<template>
  <div>
    <div class="titles">{{ __("Name") }}</div>
    <div class="details">{{ taskInfo.task_name }}</div>
    <div class="titles">{{ __("ID") }}</div>
    <div class="details">{{ taskInfo.task_id }}</div>
    <div class="titles" v-if="taskInfo.iva_collection_name">
      {{ __("Group Name") }}
    </div>
    <div class="details">{{ taskInfo.iva_collection_name }}</div>
    <div class="titles" v-if="taskInfo.tags">{{ __("Tags") }}</div>
    <div
      style="display: flex;margin-top: 10px;flex-wrap: wrap"
      v-if="taskInfo.tags"
    >
      <span
        v-for="(tag, index) in taskInfo.tags.split(',')"
        :key="`${tag}_${index}`"
        class="tag"
      >
        {{ tag }}</span
      >
    </div>
    <div v-if="taskInfo.task_type === 'voice'">
      <div class="titles">{{ __("Text to Speech Voice") }}</div>
      <div class="details">{{ getTTSVoiceName }}</div>
      <div class="titles">{{ __("Plan In Use") }}</div>
      <div class="details">{{ taskInfo.plan_in_use }}</div>
      <div class="titles">{{ __("Beep Detection") }}</div>
      <div class="details">
        {{ taskInfo.voice_task.data.beep_detection ? "on" : "off" }}
      </div>
      <div class="titles">{{ __("Machine Detection") }}</div>
      <div class="details">
        {{ taskInfo.voice_task.data.machine_detection ? "on" : "off" }}
      </div>
      <div class="titles">{{ __("Operator Transfer") }}</div>
      <div class="details">
        {{ taskInfo.voice_task.data.send_to_operator ? "yes" : "no" }}
      </div>
      <div class="titles" v-if="taskInfo.voice_task.data.send_to_operator">
        {{ __("Operator Transfer Number") }}
      </div>
      <div class="details" v-if="taskInfo.voice_task.data.send_to_operator">
        {{ taskInfo.voice_task.data.no_resource_operator_number }}
      </div>
      <div
        class="titles"
        v-if="taskInfo.phone_number && taskInfo.phone_number.length"
      >
        {{ __("Mapped Phone Numbers") }}
      </div>
      <el-table
        :data="taskInfo.phone_number"
        v-if="taskInfo.phone_number && taskInfo.phone_number.length"
        style="width: 100%"
        :show-header="false"
        size="mini"
      >
        <el-table-column prop="phone_number"></el-table-column>
      </el-table>
    </div>
    <div v-if="taskInfo.task_type === 'messaging'">
      <div class="titles">{{ __("Conversation Session Timeout") }}</div>
      <div class="details">
        {{ taskInfo.messaging_task.data.msg_session_timeout }}
      </div>
      <div
        class="titles"
        v-if="taskInfo.phone_number && taskInfo.phone_number.length"
      >
        {{ __("Mapped Phone Numbers") }}
      </div>
      <el-table
        :data="taskInfo.phone_number"
        v-if="taskInfo.phone_number && taskInfo.phone_number.length"
        style="width: 100%"
        :show-header="false"
        size="mini"
      >
        <el-table-column prop="phone_number"></el-table-column>
      </el-table>
    </div>
    <div v-if="taskInfo.task_type === 'whatsapp'">
      <div class="titles">{{ __("Conversation Session Timeout") }}</div>
      <div class="details">
        {{ taskInfo.messaging_task.data.msg_session_timeout }}
      </div>
      <div
        class="titles"
        v-if="taskInfo.phone_number && taskInfo.phone_number.length"
      >
        {{ __("Mapped Phone Numbers") }}
      </div>
      <el-table
        :data="taskInfo.phone_number"
        v-if="taskInfo.phone_number && taskInfo.phone_number.length"
        style="width: 100%"
        :show-header="false"
        size="mini"
      >
        <el-table-column prop="phone_number"></el-table-column>
      </el-table>
    </div>
    <div v-if="taskInfo.task_type === 'chatbot'">
      <div class="titles">{{ __("Conversation Session Timeout") }}</div>
      <div class="details">
        {{ taskInfo.chatbot_task.data.chatbot_session_timeout }}
      </div>
      <div class="titles">{{ __("Chatbot Task Key") }}</div>
      <div class="details">
        <div class="api-key-item">
          <span class="api-key">{{ taskInfo.chatbot_task.data.api_key }}</span>
          <span
            ><copier :value="taskInfo.chatbot_task.data.api_key"></copier
          ></span>
        </div>
      </div>
    </div>
    <div v-if="taskInfo.task_type === 'workflow'">
      <div class="titles">{{ __("Is Active") }}</div>
      <div class="details">
        {{ taskInfo.workflow_task.data.is_active ? __("Yes") : __("No") }}
      </div>

      <div class="titles">{{ __("Workflow Key") }}</div>

      <div v-if="taskInfo.workflow_task.data.api_key">
        <div class="details">
          <div class="api-key-item">
            <span class="api-key">{{
              taskInfo.workflow_task.data.api_key
            }}</span>
            <span
              ><copier :value="taskInfo.workflow_task.data.api_key"></copier
            ></span>
          </div>
        </div>
      </div>

      <div v-else class="details">
        {{ __("No api key configured. Contact support team") }}
      </div>

      <div
        class="titles"
        v-if="taskInfo.workflow_task.data.passed_params.data.length > 0"
      >
        {{ __("Passed Parameters") }}
      </div>
      <div v-if="taskInfo.workflow_task.data.passed_params.data.length > 0">
        <div
          v-for="(passed_param, index) in taskInfo.workflow_task.data
            .passed_params.data"
          class="details"
          :key="index"
        >
          {{ getPassedParameterNameFromId(passed_param.variable_id) }}
        </div>
      </div>

      <div class="titles">{{ __("Timezone") }}</div>
      <div class="details">
        {{ taskInfo.workflow_task.data.schedule_time_zone }}
      </div>

      <div class="titles">{{ __("Schedule") }}</div>
      <div class="details">
        {{ taskInfo.workflow_task.data.workflow_schedule }}
      </div>

      <div
        v-if="
          ['Once', 'Repeatedly'].includes(
            taskInfo.workflow_task.data.workflow_schedule
          )
        "
      >
        <div class="titles">{{ __("Start At") }}</div>
        <div class="details">
          {{ calendarStartAtVersion }}
        </div>

        <div
          v-if="taskInfo.workflow_task.data.workflow_schedule === 'Repeatedly'"
        >
          <div class="titles">{{ __("Schedule Summary") }}</div>
          <div class="details">
            {{
              taskInfo.workflow_task.data.schedule.data.repeat_summary ||
                taskInfo.workflow_task.data.schedule.data.repeat_frequency
            }}
          </div>
        </div>
      </div>

      <div v-else>
        <div class="titles">{{ __("Repeat Frequency") }}</div>
        <div class="details">
          {{
            humanReadableRepeatFrequency(
              taskInfo.workflow_task.data.repeat_frequency
            )
          }}
        </div>
      </div>
    </div>
    <div class="titles debug-logs">{{ __("Debug Logs") }}</div>
    <div class="details debug-logs">
      {{ taskInfo.debug_logs_enabled ? __("Enabled") : __("Disabled") }}
    </div>
    <div v-if="allowIvaCollection">
      <div class="titles">{{ __("Utility task") }}</div>
      <div class="details">
        {{ taskInfo.is_utility ? __("yes") : __("no") }}
      </div>
    </div>
    <div v-if="['workflow'].includes(taskInfo.task_type)">
      <el-dropdown
        @command="handleWorkflowAction"
        class="workflowActionDropDown"
      >
        <div>
          <el-tooltip
            effect="dark"
            placement="top"
            :content="__('You do not have permission to manage this item')"
            :disabled="this.canWrite()"
          >
            <span class="wrapper">
              <el-button type="primary" :disabled="!this.canWrite()">
                {{ __("Actions")
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
            </span>
          </el-tooltip>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(command, index) in commandsToShow"
            :key="index"
            :command="command.command"
            :disabled="!canWrite()"
            v-show="showAction(taskInfo)"
          >
            <div
              style="display: flex; justify-content: flex-start; align-items: center"
            >
              <img
                :src="getIconSVG(command.icon)"
                alt
                height="18px"
                width="18px"
                style="margin-right: 10px"
              />
              <span>{{ command.label }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div v-else-if="['chatbot'].includes(taskInfo.task_type)">
      <el-dropdown
        @command="handleChatbotAction"
        class="workflowActionDropDown"
      >
        <div>
          <el-tooltip
            effect="dark"
            placement="top"
            :content="__('You do not have permission to manage this item')"
            :disabled="this.canWrite()"
          >
            <span class="wrapper">
              <el-button type="primary" :disabled="!this.canWrite()">
                {{ __("Actions")
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
            </span>
          </el-tooltip>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(command, index) in commandsToShow"
            :key="index"
            :command="command.command"
            :disabled="!canWrite()"
            v-show="showAction(taskInfo)"
          >
            <div
              style="display: flex; justify-content: flex-start; align-items: center"
            >
              <img
                :src="getIconSVG(command.icon)"
                alt
                height="18px"
                width="18px"
                style="margin-right: 10px"
              />
              <span>{{ command.label }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div v-else style="display: flex; margin-bottom: 40px">
      <el-tooltip
        effect="dark"
        placement="bottom"
        :content="__('You do not have permission to manage this item')"
        :open-delay="500"
        :disabled="this.canWrite()"
      >
        <span class="wrapper">
          <el-button
            @click="$emit('edit')"
            class="editBtn"
            v-show="showAction(taskInfo)"
            :disabled="!this.canWrite()"
            >{{ __("Edit Task") }}
          </el-button>
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import Copier from "@/components/Copier";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { formatAsCalendarWithTz } from "@/utils/time";
export default {
  name: "TaskInfoPanel",
  components: { Copier },
  props: {
    taskInfo: {
      required: true,
      type: Object
    },
    languages: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      commands: [
        {
          command: "edit",
          rules: {
            task_type: ["workflow", "chatbot"]
          },
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit")
        },
        {
          command: "TriggerWorkflow",
          rules: {
            task_type: ["workflow"]
          },
          icon: "campaign_actions/icon_call_out.svg",
          label: __("Run Now")
        },
        {
          command: "ApiInfo",
          rules: {
            task_type: ["workflow", "chatbot"]
          },
          icon: "campaign_actions/icon_info_sign.svg",
          label: __("Api Info")
        }
      ],
      allowIvaCollection: false
    };
  },
  computed: {
    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables",
      getPassedParameterNameFromId: "getPassedParameterNameFromId"
    }),
    calendarStartAtVersion() {
      return formatAsCalendarWithTz(
        this.taskInfo.workflow_task.data.start_on,
        this.taskInfo.workflow_task.data.schedule_time_zone
      );
    },
    getTTSVoiceName() {
      let language = _.find(_.flatMap(this.languages), language => {
        return (
          language.tts_voice_id === this.taskInfo.voice_task.data.tts_voice_id
        );
      });
      return language ? language.voice_name : "";
    },
    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return rule.includes(this.taskInfo[key].toLowerCase());
        });
      });
    },
    showAction() {
      return taskInfo => {
        if (!taskInfo.protected) {
          return true;
        } else return !(taskInfo.protected && !this.can("build.tasks.lock"));
      };
    }
  },
  methods: {
    ...mapActions("tasks", {
      deleteContentMethod: "deleteTask",
      undoDeleteContent: "undoDeleteTask"
    }),
    handleWorkflowAction(command) {
      this.$emit("command", command);
    },

    handleChatbotAction(command) {
      if (["edit", "delete"].includes(command)) {
        this.$emit("command", command);
      } else if (command === "ApiInfo") {
        window.open(
          "https://documenter.getpostman.com/view/12269540/TW77fhwA#d0e601d4-1177-4c86-85e6-0133acbeea19",
          "_blank"
        );
      }
    },

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    humanReadableRepeatFrequency(frequency) {
      let parts = frequency.split(":");
      if (!+parts[0]) {
        return __("Every :minute minute:s", {
          minute: +parts[1],
          s: +parts[1] === 1 ? "" : __("s")
        });
      }

      if (+parts[0] && !+parts[1]) {
        return __("Every :hour hour:s", {
          hour: +parts[0],
          s: +parts[0] === 1 ? "" : __("s")
        });
      }

      return __("Every :hour hour:s and :minute minute:ms", {
        hour: +parts[0],
        minute: +parts[1],
        s: +parts[0] === 1 ? "" : __("s"),
        ms: +parts[1] === 1 ? "" : __("s")
      });
    },

    async toggleNewTaskExportLayout() {
      this.allowIvaCollection = await this.showFeature(
        this.$getConst("MANAGE_IVA_COLLECTION")
      );
    }
  },
  watch: {
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.useNewTaskExportLayout = false;
        } else {
          await this.toggleNewTaskExportLayout();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/button.scss";

.editBtn.is-disabled {
  color: white;
  //background-color: mix(white, $content-theme-color, 50%);
  border-color: $content-theme-outline-color !important;
}

.workflowActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;

    &:disabled {
      color: white;
      //background-color: mix(white, $content-theme-color, 50%);
      border-color: $content-theme-outline-color !important;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}

.tag {
  background: #f5f5f5;
  padding: 5px;
  color: #a0a8b5;
  margin: 2px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  border: #a0a8b5 1px solid;
}

.api-key-item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .api-key {
    word-break: break-word;
    padding-right: 5px;
  }
}
</style>
